.component-page {
    height: 100%;
    position: relative;


    .destroyer {
        position: fixed;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 56px;
        height: 208px;
        padding: 20px 0px;
        background: #ffffff;
        border-radius: 28px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .itemBox {
            position: relative;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                .item {
                    width: 100%;
                    padding: 5px 0px;
                    background: #EBEEF2;
                }

                .frigate {
                    visibility: visible;
                }
            }
        }

        .icon {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 18px;
        }

        .text {
            margin-top: 7px;
            font-size: 10px;
            font-family: OPPOSans, OPPOSans-Medium;
            font-weight: 500;
            text-align: center;
            color: #646a73;
            line-height: 13px;
            transform: scale(.8);
        }
    }

    .frigate {
        background: #ffffff;
        position: absolute;
        top: 50%;
        right: 100%;
        width: 196px;
        height: 104px;
        margin-right: 3px;
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
        text-align: right;
        padding-right: 20px;
        row-gap: 3px;
        visibility: hidden;

        .text1 {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #000000;
            line-height: 22px;
        }

        .text2 {
            background-image: url("./imgs/destroyer/4.png");
            background-repeat: no-repeat;
            background-position: 22px center;
            background-size: 15px 15px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #25429c;
            line-height: 25px;
        }

        .text3 {
            font-size: 11px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #000000;
            line-height: 16px;
        }

        .anticon-phone {
            transform: rotate(90deg);
        }
    }
}

.component-banner {
    background-position: center center;
    background-size: 100% 100%;
    height: 500px;
    position: relative;

    .content {
        position: absolute;
        top: calc(50% + 44px);
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        text-align: center;

        .title {
            font-size: 52px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            line-height: 73px;
        }

        .description {
            margin-top: 10px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            line-height: 27px;
            white-space: nowrap;
        }
    }
}

.component-header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    transition: all .3s linear;

    &__rendered {
        height: 68px;
        display: flex;
        align-items: center;
    }

    &:hover {
        background: rgba($color: #ffffff, $alpha: 0.9);

        .logo {
            background-image: url(https://public.img.oss.shequbanjing.com/property/application/13ef818d-5e26-4b72-952d-025d3de705ed.png);
        }

        .navigation {
            .nav {
                color: #132150;
            }

            .hasSubnav {
                background-image: url(./imgs/header/arrow2.png);
            }
        }

        .phone {
            background-image: url(./imgs/header/phone2.png);
            color: #132150;
        }
    }

    .logo {
        background-image: url(https://public.img.oss.shequbanjing.com/property/application/38ac2f68-cced-4049-b708-33bd24c6fe4e.png);
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
        width: 200px;
        height: 20px;
    }

    .navigation {
        position: relative;
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: right;
        align-items: center;
        column-gap: 50px;

        .nav {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            line-height: 18px;
            letter-spacing: 0.2px;
            transition: all .3s linear;
            cursor: pointer;

            &::before {
                content: " ";
                display: block;
                position: absolute;
                top: 0;
                width: 53px;
                height: 4px;
                margin: auto;
                background: transparent;
                transition: all .3s linear;
            }

            &.active {
                &::before {
                    background: #fff;
                }
            }

            &:hover {
                color: #0D36A1;

                &::before {
                    background: #0D36A1;
                }
            }
        }

        .subnavBox {
            background: #fff;
            position: absolute;
            top: 100%;
            width: 678px;
            display: flex;
            flex-wrap: wrap;
            padding: 7px 0px;
            border-radius: 3px;
            box-shadow: 0px 3px 3px 2px rgba(0, 0, 0, 0.20);

            .subnav {
                width: 226px;
                height: 72px;
                box-sizing: border-box;
                padding: 15px 10px;
                border: 1px solid transparent;
                cursor: pointer;

                &:hover {
                    .subnav__rendered {
                        background: #f0f4ff;
                    }
                }

                .subnav__rendered {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    border-radius: 6px;
                    transition: all .3s linear;
                }

                &.rightBorder {
                    border-right-color: #EBF0F9;
                }

                &.bottomBorder {
                    border-bottom-color: #EBF0F9;
                }

                .icon {
                    background-position: 33px center;
                    background-size: 20px 20px;
                    background-repeat: no-repeat;
                    width: 63px;
                    height: 20px;
                }

                .text {
                    flex: 1;
                    font-family: OPPOSans, OPPOSans-Medium;
                    font-weight: 500;
                    color: #1e2330;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                }
            }
        }

        .hasSubnav {
            padding-right: 20px;
            background-position: right center;
            background-size: 14px auto;
            background-repeat: no-repeat;
            background-image: url(./imgs/header/arrow3.png);

            &.open {
                background-image: url(./imgs/header/arrow1.png);
            }
        }
    }

    .phone {
        background-image: url(./imgs/header/phone.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 16px;
        padding-left: 25px;
        margin-left: 85px;
        font-size: 16px;
        font-family: DIN Alternate, DIN Alternate-Bold;
        font-weight: 700;
        text-align: left;
        color: #ffffff;
        line-height: 18px;
        letter-spacing: 0.23px;
    }
}

.component-footer {
    background: #101010;
    padding: 23px 120px 0px;
    color: #fff;

    a {
        color: #fff;
    }

    .friendsLink {
        display: flex;
        column-gap: 20px;

        .right {
            flex: 1;
            display: flex;
            column-gap: 20px;
            justify-content: space-around;
        }
    }

    .divider {
        height: 1px;
        margin: 20px -20px 36px;
        background: #3D3D3D;
    }

    .itemList {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .item {
            display: flex;
            column-gap: 30px;
        }
    }

    .layout {
        display: flex;
        .left {
            width: 50%;
            justify-content: left !important;
        }
        .right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: right;
        }

        .appBox {
            display: flex;
            column-gap: 20px;

            .img {
                background-size: 100% 100%;
                width: 100px;
                height: 100px;
                margin: auto;
            }

            .text {
                margin-top: 11px;
                font-size: 12px;
                font-family: OPPOSans, OPPOSans-Medium;
                font-weight: 500;
                color: #ffffff;
                line-height: 16px;
            }
        }
    }

    .copyright {
        color: #bbb;
        display: flex;
        column-gap: 20px;
        margin-left: -120px;
        margin-right: -120px;
        justify-content: center;
        margin-top: 30px;
        font-size: 12px;

        a {
            color: #bbb;
        }
        img {
            width: 14px;
            height: 14px;
        }
    }
}

.component-flexBox {
    margin: auto;
    padding: 0px 110px;
}

.component-imgBox {
    width: 100%;
    margin: 0;
    object-fit: fill;
}

.component-bgBox {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.component-blockTitle {
    font-size: 40px;
    font-family: OPPOSans, OPPOSans-Bold;
    font-weight: 700;
    color: #111111;
    line-height: 64px;
    text-align: center;
}

.component-blockDescription {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #353535;
    line-height: 27px;
}

.component-itemList {
    display: flex;
    justify-content: space-between;

    &.card {
        .item {
            background: linear-gradient(180deg, #ffffff, #ffffff);
            border-radius: 8px;
            box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.10);

            // padding: 20px 0px;
            // text-align: center;
        }
    }

    .item {
        text-align: center;
    }

    .itemIcon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .itemImg {
        background-position: center;
        background-size: 100% 100%;
    }

    .itemTitle {
        margin-top: 15px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
    }

    .itemText {
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #686868;
        line-height: 24px;
    }
}

.component-layout {
    display: flex;

    .center {
        flex: 1;
    }

    &.LR {
        .right {
            flex: 1;
        }
    }
}

// @media screen and (max-width: 1024px) {
//     .component-flexBox {
//         width: 900px;
//     }
// }

// @media screen and (min-width: 1024px) and (max-width: 1366px) {
//     .component-flexBox {
//         width: 980px;
//     }
// }

// @media screen and (min-width: 1366px) and (max-width: 1440px) {
//     .component-flexBox {
//         width: 1003px;
//     }
// }

// @media screen and (min-width: 1440px) and (max-width: 1920px) {
//     .component-flexBox {
//         width: 1200px;
//     }
// }

@media screen and (min-width: 1200px) {
    .component-flexBox {
        width: 1200px;
    }
}