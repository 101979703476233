.home {
    @keyframes zoom {
        0% {
            transform: scale(1);
        }

        60% {
            transform: scale(1.05);
        }

        100% {
            transform: scale(1.05);
        }
    }

    @keyframes in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    .banner {
        position: relative;
        height: 620px;
        overflow: hidden;

        .item {
            position: absolute;
            left: 100%;
            top: 0;
            width: 100%;
            height: 100%;
            transition: all .6s ease-in-out;
            visibility: hidden;
            z-index: 0;

            &.in {
                visibility: visible;
                left: 0;
                z-index: 10;
            }

            &.out {
                visibility: visible;
                left: -100%;
                z-index: 5;
            }

            .bg {
                background-size: 100% 100%;
                width: 100%;
                height: 100%;
            }

            .content {
                position: absolute;
                top: calc(50% + 44px);
                left: 0;
                right: 0;
                transform: translateY(-50%);

                // .component-flexBox {
                //     padding-left: 270px;
                // }

                .appLogo {
                    background-size: 100% 100%;
                    width: 70px;
                    height: 70px;
                }

                .title {
                    color: #ffffff;
                    font-size: 52px;
                    font-family: OPPOSans, OPPOSans-Regular;
                    font-weight: 700;
                    line-height: 69px;
                }

                .titleImg {
                    background-size: 100% 100%;
                    width: 473px;
                    height: 125px;
                }

                .description {
                    color: #ffffff;
                    margin-top: 27px;
                    font-size: 20px;
                    font-family: OPPOSans, OPPOSans-Regular;
                    font-weight: 400;
                    line-height: 35px;
                    letter-spacing: 0.43px;
                }

                .btn {
                    margin-top: 30px;
                    width: 200px;
                    height: 60px;
                    background: #183fb8;
                    border-radius: 3px;
                    font-size: 20px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    text-align: center;
                    line-height: 60px;
                    color: #ffffff;
                    cursor: pointer;
                }
            }
        }

        .switchBar-box {
            position: absolute;
            bottom: 70px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            column-gap: 10px;
            z-index: 20;

            .switchBar {
                background: #fff;
                width: 70px;
                height: 3px;
                border-radius: 1px;
                opacity: 0.5;
                cursor: pointer;

                &.active {
                    opacity: 1;
                }
            }
        }
    }

    .block1 {
        position: relative;
        padding: 40px 0px 73px;

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            opacity: 0.5;
            background-image: url(http://dev-public.img.oss.shequbanjing.com/property/application/bd6bea7f-34f0-48d2-8c5b-78f8f015a2b7.png);
            background-size: 290px auto;
            background-position: left top;
            background-repeat: no-repeat;
            z-index: -1;
        }

        &::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 100%;
            opacity: 0.5;
            background-image: url(http://dev-public.img.oss.shequbanjing.com/property/application/dd68dd5f-6be1-4edc-8582-146d71fa63b7.png);
            background-size: 280px auto;
            background-position: right top;
            background-repeat: no-repeat;
            z-index: -1;
        }

        .component-itemList {
            margin-top: 44px;
            flex-wrap: wrap;
            row-gap: 50px;

            .item {
                width: 20%;
            }

            .itemIcon {
                height: 74px;
            }

            .itemTitle {
                margin-top: 23px;
                font-weight: 600;
            }
        }

        .btns {
            display: flex;
            justify-content: center;
            column-gap: 16px;
            text-align: center;
            margin-top: 70px;

            .btn {
                width: 140px;
                height: 50px;
                border-radius: 2px;
                font-size: 16px;
                font-family: OPPOSans, OPPOSans-Medium;
                font-weight: 500;
                line-height: 50px;
                border: 1px solid #cccccc;
                color: #222222;
                cursor: pointer;

                &.primary {
                    background: #25429c;
                    color: #ffffff;
                }
            }
        }
    }

    .block2 {
        background-size: 100% 100%;
        padding: 40px 0px 116px;

        .component-blockTitle {
            color: #fff;
        }

        .component-blockDescription {
            color: #fff;
        }

        .component-itemList {
            margin-top: 47px;

            .item {
                background-size: 100% 100%;
                background-position: center;
                flex: 1;
                height: 420px;
                border-radius: 0;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    background-size: 120% 120%;
                    transition: all .3s linear;
                }
            }

            .itemIcon {
                height: 42px;
            }

            .itemTitle {
                margin-top: 43px;
                font-size: 24px;
                font-weight: 600;
                color: #ffffff;
                line-height: 34px;
            }
        }
    }

    .block3 {
        height: 547px;
        padding-top: 50px;
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);

        .component-flexBox {
            position: relative;
        }

        .content {
            position: absolute;
            top: 126px;
            left: 110px;
            right: 110px;
            height: 514px;
            background: #ffffff;

            .title {
                padding: 0px 32px;
                font-size: 20px;
                font-family: OPPOSans, OPPOSans-Bold;
                font-weight: 700;
                color: #111111;
                line-height: 31px;
            }

            .description {
                margin-top: 14px;
                padding: 0px 32px;
                font-size: 14px;
                font-family: OPPOSans, OPPOSans-Regular;
                font-weight: 400;
                color: #818181;
                line-height: 21px;
            }
        }
    }

    .block4 {
        background-size: 100% 100%;
        height: 442px;

        .component-flexBox {
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .content {
            margin-bottom: 87px;

            .title {
                font-size: 32px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                color: #ffffff;
                line-height: 43px;
            }

            .form {
                margin-top: 20px;
                display: flex;

                input {
                    width: 160px;
                    padding-left: 10px;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        font-size: 16px;
                        color: #d5d5d5;
                    }

                    &.large {
                        width: 220px;
                    }
                }
            }

            .btn {
                width: 136px;
                background: #e02020;
                border-radius: 2px;
                font-size: 21px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                text-align: center;
                color: #ffffff;
                line-height: 46px;
                cursor: pointer;
            }
        }
    }
}

.guanYuWoMen {

    .block1 {
        padding: 40px 0px 40px;

        .description {
            margin-top: 24px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #232323;
            line-height: 24px;
        }
    }

    .block2 {
        padding: 58px 0px;
        background: linear-gradient(135deg, #01172e 6%, #000d24 94%);

        .component-itemList {
            column-gap: 36px;
            height: 413px;
            justify-content: center;

            .item {
                background-position: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 237px;
                display: flex;
                align-items: flex-end;
                overflow: hidden;

                .itemTitle {
                    margin-top: 43px;
                    padding: 0px 20px;
                    font-size: 24px;
                    line-height: 24px;
                    color: #fff;
                }

                .itemText {
                    margin-top: 20px;
                    padding: 0px 20px;
                    color: #fff;
                }
            }

            .box {
                background: linear-gradient(180deg, rgba(67, 77, 88, 0.00), #020202 54%);
                height: 164px;
                text-align: left;
            }
        }
    }

    .block3 {
        padding: 50px 0px 76px;
    }
}

.yeCaiYiTiHua {
    .block1 {
        padding: 45px 0px 70px;

        .layoutLR {
            margin-top: 26px;
            padding-top: 32px;
            display: flex;
            border-top: 1px solid #EBF0F9;

            .left {
                background-size: 100% 100%;
                width: 404px;
                height: 371px;
                margin-left: 20px;
            }

            .right {
                margin-left: 80px;
                flex: 1;
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                justify-content: center;
            }
        }

        .group {

            .text1 {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                color: #25429c;
                line-height: 30px;
            }

            .text2 {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                color: #686868;
                line-height: 24px;
            }
        }
    }

    .block2 {
        background-image: url(http://dev-public.img.oss.shequbanjing.com/property/application/6f101454-3fb8-46cb-9020-cdb2554e55f1.png);
        background-size: 100% 100%;

        .layoutLR {
            display: flex;
            height: 427px;

            .left {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .textBox {
                    width: 387px;
                    height: 303px;
                    background: linear-gradient(180deg, #ffffff, #ffffff);
                    border-radius: 8px;
                    padding: 38px;
                }

                .text1 {
                    font-size: 32px;
                    font-family: PingFang SC, PingFang SC-Semibold;
                    font-weight: 600;
                    color: #234fd7;
                    line-height: 32px;
                }

                .text2 {
                    margin-top: 20px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    color: #111111;
                    line-height: 36px;
                }
            }

            .right {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .imgBox {
                    background-size: 100% 100%;
                    width: 412px;
                    height: 412px;
                }
            }
        }
    }

    .block3 {
        padding: 56px 0px 70px;
    }

    .block4 {
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
        padding: 56px 0 21px;

        .layoutLR {
            display: flex;
            justify-content: space-evenly;

            .left {
                margin-top: 75px;
                width: 558px;

                .title {
                    font-size: 28px;
                    font-family: PingFang SC, PingFang SC-Semibold;
                    font-weight: 600;
                    text-align: left;
                    color: #222222;
                    line-height: 28px;
                    letter-spacing: 5.25px;
                }

                .description {
                    margin-top: 20px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    color: #222222;
                    line-height: 28px;
                    letter-spacing: 1px;
                }

                .cards {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 33px;
                    column-gap: 20px;
                    row-gap: 20px;

                    .card {
                        background-size: 100% 100%;
                        width: 247px;
                        padding: 20px 25px;
                        border-radius: 8px;
                    }

                    .text1 {
                        font-size: 18px;
                        font-family: PingFang SC, PingFang SC-Semibold;
                        font-weight: 600;
                        color: #212121;
                        line-height: 25px;
                    }

                    .text2 {
                        margin-top: 9px;
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        color: #595959;
                        line-height: 24px;
                    }
                }
            }

            .right {
                width: 333px;
            }
        }
    }
}

.zhiHuiWuGuan {
    .block1 {
        padding: 59px 0px 73px;

        .component-itemList.a {
            padding: 50px 0px 24px;
            border-bottom: 1px solid #EBF0F9;
            justify-content: space-evenly;

            .itemIcon {
                height: 33px;
            }
        }

        .component-itemList.b {
            flex-wrap: wrap;
            row-gap: 26px;

            .item {
                width: 225px;
                height: 160px;
            }

            .itemIcon {
                height: 24px;
                margin-top: 20px;
            }

            .itemTitle {
                color: #25429c;
            }
        }
    }

    .block2 {
        background: #EBF0F9;
        padding: 57px 0px 70px;

        .component-layout {
            background: #ffffff;
            border-radius: 16px;
            box-shadow: 0px 8px 16px 7px rgba(82, 90, 99, 0.16);
            overflow: hidden;
            height: 370px;

            .left {
                background: #ebf1ff;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                justify-content: center;
                align-items: center;
                text-align: center;

                .item {
                    width: 142px;
                    height: 50px;
                    background: #fff;
                    border-radius: 8px;
                    font-size: 20px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    color: #000000;
                    line-height: 50px;
                    cursor: pointer;

                    &.active {
                        background: #d0dbf9;
                    }
                }
            }

            .center {
                padding: 0px 45px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .itemTitle {
                    font-size: 18px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    line-height: 28px;
                    color: #111111;
                }

                .itemText {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    line-height: 28px;
                    color: #505050;
                }
            }

            .right {
                padding-right: 7px;
                border-radius: 8px;
                display: flex;
                align-items: center;
            }
        }

        .component-itemList {
            margin-top: 50px;
            justify-content: center;
            column-gap: 33px;

            .itemIcon {
                width: 188px;
                height: 55px;
            }
        }
    }

    .block3 {
        padding: 57px 0px 70px;

        .component-itemList {
            column-gap: 11px;

            .item {
                flex: 1;
                height: 312px;
            }

            .itemImg {
                height: 130px;
            }

            .itemTitle {
                margin-top: 20px;
            }

            .itemText {
                padding: 0px 20px;
                text-align: left;
            }
        }
    }

    .block4 {
        position: relative;
        padding: 56px 0px 69px;
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
            z-index: -1;
        }

        .component-itemList {
            column-gap: 20px;
            row-gap: 27px;
            flex-wrap: wrap;
            justify-content: center;

            .item {
                width: 380px;
                opacity: 0.7;
                background: #ffffff;
            }
        }

        .component-layout {
            height: 114px;

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 20px;
            }
        }


        .icon {
            background-size: 100% 100%;
            width: 30px;
            height: 30px;
        }

        .title {
            width: 138px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #111111;
            line-height: 28px;
            text-align: left;
        }

        .description {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
            text-align: left;
        }
    }

    .block5 {
        padding: 50px 0px 70px;
    }
}

.renLiZiYuan {
    .component-layout {
        .left {
            display: flex;
            justify-content: center;
        }

        .content {
            flex: 1;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #1e2330;
            line-height: 32px;
        }

        .btn {
            width: 140px;
            height: 50px;
            background: #25429c;
            margin-top: 60px;
            border-radius: 2px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 50px;
        }
    }

    .block1 {
        padding: 58px 0px 0px;

        .component-itemList.a {
            flex-wrap: wrap;
            row-gap: 30px;

            .item {
                background-size: 100% 100%;
                width: 469px;
                height: 346px;
                padding: 208px 34px 0px 34px;
                text-align: left;
            }

            .itemTitle {
                font-size: 24px;
                font-weight: 600;
                color: #ffffff;
            }

            .itemText {
                font-weight: 500;
                color: #ffffff;
            }
        }

        .component-itemList.b {
            background: #F1F5FC;
            padding: 48px 0px 61px;
            justify-content: left;
            flex-wrap: wrap;
            row-gap: 53px;

            .item {
                width: 20%;
            }

            .itemIcon {
                height: 35px;
            }

            .itemTitle {
                font-size: 16px;
                font-weight: 400;
                color: #1e2330;
            }
        }
    }

    .block2 {
        padding: 60px 0px 70px;
    }

    .block3 {
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
        padding: 55px 0px 70px;

        .component-itemList {
            margin-top: 44px;
            column-gap: 11px;

            .item {
                flex: 1;
                height: 310px;
            }

            .itemImg {
                height: 130px;
            }

            .itemTitle {
                margin-top: 20px;
            }

            .itemText {
                padding: 0px 31px;
                text-align: left;
            }
        }
    }

    .block4 {
        padding: 50px 0px 25px;
    }

    .block5 {
        padding: 25px 0px 90px;
    }
}

.banGongXieTong {

    .component-layout {
        margin-top: 30px;

        .right {
            margin-top: 20px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #1E2330;
            line-height: 34px;

            .hasColon {
                color: #25429C;

                &::before {
                    content: " ";
                    display: inline-block;
                    vertical-align: top;
                    width: 5px;
                    height: 5px;
                    background: #000000;
                    border-radius: 50%;
                    margin-top: 15px;
                    margin-right: 15px;
                }
            }
        }
    }

    .block1 {
        padding: 70px 0 70px;
    }

    .block2 {
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
        padding: 58px 0 66px;
    }

    .block3 {
        padding: 64px 0 83px;
    }

    .block4 {
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
        padding: 58px 0 100px;
    }
}

.guanLiJiaShiCang {

    .block1 {
        position: relative;
        padding: 45px 0px 70px;

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            width: 226px;
            height: 234px;
            opacity: 0.5;
            background-image: url(http://dev-public.img.oss.shequbanjing.com/property/application/d6139f45-5491-4928-a50a-2ff36c6c2bdb.png);
            background-size: 100% 100%;
            z-index: -1;
        }

        &::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 0;
            width: 291px;
            height: 371px;
            opacity: 0.5;
            background-image: url(http://dev-public.img.oss.shequbanjing.com/property/application/dd68dd5f-6be1-4edc-8582-146d71fa63b7.png);
            background-size: 100% 100%;
            z-index: -1;
        }

        .component-layout {
            height: 300px;
            background: #ffffff;
            border-radius: 12px;
            box-shadow: 0px 12px 16px 7px rgba(82, 90, 99, 0.08);
            display: flex;
            align-items: center;

            .left {
                flex: 1;
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .right {
                padding-right: 25px;

                .title {
                    font-size: 20px;
                    font-family: PingFang SC, PingFang SC-Semibold;
                    font-weight: 600;
                    color: #25429c;
                    line-height: 24px;
                }

                .text {
                    margin-top: 15px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    color: #686868;
                    line-height: 26px;
                }
            }
        }
    }

    .block2 {
        background-image: url(http://dev-public.img.oss.shequbanjing.com/property/application/f4489f2e-8ab7-4a4b-b812-7480c5fc7c4b.png);
        background-size: 100% 100%;
        padding: 60px 0px 70px;

        .component-blockTitle {
            color: #fff;
        }

        .component-blockDescription {
            color: #fff;
        }
    }
}

.wuLianWangJieJueFangAn {

    .block1 {
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
        padding: 50px 0px 70px;
    }

    .block2 {
        padding: 50px 0px 70px;

        .component-itemList.a {
            column-gap: 25px;

            .item {
                flex: 1;
                height: 160px;
            }

            .itemIcon {
                margin-top: 20px;
                height: 25px;
            }

            .itemTitle {
                margin-top: 20px;
                color: #25429c;
            }
        }

        .component-itemList.b {

            .item {
                width: 470px;
                height: 360;
            }

            .itemImg {
                height: 200px;
            }

            .itemText {
                display: flex;
                align-items: center;
                height: 160px;
                padding: 0px 30px 0px;
                color: #1e2330;
                text-align: left;
            }
        }
    }

    .block3 {
        background: linear-gradient(0deg, #f5faff 6%, #ebf0f9);
        padding: 50px 0px 70px;

        .component-itemList {
            justify-content: space-evenly;

            .item {
                height: 45px;
                padding: 0px 18px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                color: #646a73;
                line-height: 45px;
                border-radius: 4px;
                cursor: pointer;

                &.active {
                    background: #D4DDF9;
                    color: #25429C;
                }
            }
        }

        .component-layout {
            background: #ffffff;
            border-radius: 16px;
            box-shadow: 0px 8px 16px 7px rgba(82, 90, 99, 0.16);
            height: 278px;

            .left {
                padding: 7px 0px 0px 6px;
            }

            .right {
                display: flex;
                align-items: center;
                background-position: right center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }

            .content {
                padding-left: 54px;

                .title {
                    font-size: 24px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    color: #111111;
                    line-height: 28px;
                }

                .text {
                    margin-top: 20px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    color: #686868;
                    line-height: 24px;
                }

                .btn {
                    width: 140px;
                    background: #25429c;
                    border-radius: 2px;
                    margin-top: 30px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 50px;
                }
            }
        }
    }

    .block4 {
        padding: 50px 0px 70px;

        .component-itemList.a {
            margin-top: 30px;
            column-gap: 15px;

            .item {
                height: 160px;
            }

            .itemIcon {
                margin-top: 23px;
                height: 24px;
            }

            .itemTitle {
                color: #25429c;
            }
        }

        .component-itemList.b {
            margin-top: 40px;
            column-gap: 11px;

            .item {
                flex: 1;
                height: 263px;
            }

            .itemTitle {
                margin-top: 20px;
            }

            .itemText {
                margin-top: 10px;
            }

            .itemImg {
                height: 130px;
            }
        }
    }
}

.pingPaiDongTai {
    .component-blockTitle {
        text-align: left;
    }

    .component-blockDescription {
        text-align: left;
    }

    .block1 {
        padding: 50px 0px;

        .component-layout {
            margin-top: 50px;

            .component-imgBox {
                border-radius: 6px;
            }

            .right {
                padding-left: 40px;
            }

            .title {
                min-height: 60px;
                margin-top: 15px;
                font-size: 22px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                color: #333333;
                line-height: 30px;
            }

            .description {
                margin-top: 16px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                color: #aaaaaa;
                line-height: 24px;
            }
        }
    }

    .block2 {
        .group {
            padding: 68px 0px 52px;

            &.hasBg {
                background: linear-gradient(135deg, #f5faff 6%, #ebf0f9 94%);
            }

            font-family: PingFang SC,
            PingFang SC-Medium;
            font-weight: 500;
            text-align: left;
            line-height: 34px;
        }

        .title {
            margin-top: 13px;
            font-size: 24px;
            color: #111111;
        }

        .subtitle {
            margin-top: 14px;
            color: #347AEB;
            font-size: 16px;
        }

        .description {
            margin-top: 5px;
            font-size: 14px;
            color: #111111;
            font-weight: 400;
            line-height: 1.5em;
        }
    }

    .link:hover {

        .title,
        .description {
            cursor: pointer;
            color: #1E4095;
        }
    }
}

.pingPaiDongTai-news {
    .block {
        padding-top: 60px;
        padding-bottom: 76px;
    }

    .title {
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #111111;
        line-height: 40px;
    }

    .p {
        margin-top: 30px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        text-indent: 2em;
    }

    .component-layout {
        margin-top: 46px;

        .left,
        .right {
            text-align: center;
        }
    }
}

.shengTaiYuHeZuo {
    .component-banner {
        height: 610px;
    }

    .block {
        background: linear-gradient(180deg, #f6f7fa 27%, #f6f7fa);
        position: relative;
        padding: 40px 42px 55px;
        margin: -64px auto 72px;
        border-radius: 12px;

        .tips {
            font-size: 32px;
            font-weight: 700;
            text-align: center;
            color: #111111;
            line-height: 50px;
        }

        .form {
            margin-top: 36px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            column-gap: 88px;
            row-gap: 47px;

            .formItem {
                background: #fff;
                flex: calc(50% - 44px);
                height: 65px;
                display: flex;
                align-items: center;
                border-radius: 4px;

                &.large {
                    height: 314px;
                }
            }

            input,
            textarea {
                width: 100%;
                height: 100%;
                border: none;
                padding: 18px 11px;
                box-sizing: border-box;
                line-height: 28px;
                font-size: 20px;

                &:focus {
                    border: none;
                    outline: none;
                }

                &::placeholder {
                    font-size: 20px;
                    font-weight: 400;
                    color: #cfcfcf;
                    line-height: 28px;
                    letter-spacing: 0.28px;
                }
            }
        }

        .submitBtn {
            width: 177px;
            height: 50px;
            background: #0e73d0;
            border-radius: 6px;
            margin: 65px auto 0;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            color: #ffffff;
            line-height: 50px;
            cursor: pointer;
        }
    }
}